import { ElLoading } from 'element-plus'
import axios from 'axios'
import { server } from '@/commons/domain'
import * as CryptoJS from 'crypto-js'

let loadingCount = 0
let loading = null

function sign(headers, method, url) {
  var timestamp = Math.round(new Date()) + 500

  const appKey = 'gurobot'
  const deviceCode = 'CHUANYU2'
  const appSecret = 'RsSVrmrrQLfkwDo9Z2tKGq1FgvY5rV'
  let pos1 = url.indexOf("/")
  let pos2 = url.indexOf("?")
  let path = ""
  let params = ""
  let textToSign = ""
  if (pos2 != -1) {
    // 有参数
    path = url.substr(pos1, pos2 - pos1)
    params = url.substr(pos2 + 1, url.length - pos2 - 1)
    textToSign = method + '&uri=' + path + '&' + params + '&x-app-key=' + appKey + '&x-device-code=' + deviceCode + '&x-timestamp=' + timestamp
  } else {
    // 无参数
    path = url.substr(pos1, url.length - pos1)
    textToSign = method + '&uri=' + path + '&x-app-key=' + appKey + '&x-device-code=' + deviceCode + '&x-timestamp=' + timestamp
  }
  console.log("textToSign: " + textToSign)

  const hash = CryptoJS.HmacSHA1(textToSign, appSecret)
  const signature = hash.toString(CryptoJS.enc.Base64)

  headers['x-app-key'] = appKey
  headers['x-device-code'] = deviceCode
  headers['timestamp'] = `${timestamp}`
  headers['x-sig'] = signature
}

export default {
  data(){
    return {

    }
  },

  methods: {
    showLoading(text) {
      loadingCount++
      if (!loading) {
        loading = ElLoading.service({ fullscreen: true, text })
      }
    },

    hideLoading() {
      loadingCount--
      if (loadingCount <= 0 && loading) {
        loading.close()
        loading = null
      }
    },

    async confirm(content, title) {
      try {
        await this.$confirm(content, title || '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        return true
      } catch(e) {
        return false
      }
    },

    async request(opts) {
      if(!opts.headers){
        opts.headers = {}
      }

      const token = this.$store.state.user.token || ''
      if (token) {
        opts.headers['Authorization'] = `Bearer ${token}`
      }

      let url = opts.url
      let params = [];
      if(opts.params){
        for(let key in opts.params){
          let val = opts.params[key];
          if(val === undefined || val === null || val === ''){
            params.push(`${key}=`);
          }else{
            params.push(`${key}=${decodeURIComponent(val)}`)
          }
        }
      }
      url += url.indexOf('?') !== -1 ? `&${params.join('&')}` : `?${params.join('&')}`
      const method = (opts.method || 'POST').toUpperCase()
      sign(opts.headers, method, url)

      try {
        let data = await this.$http({
          method: opts.method || 'POST',
          headers: opts.headers,
          url: `${server}${url}`,
          data: opts.data,
          timeout: opts.timeout || 300000
        })

        if(data.errno !== 0){
          this.$message.error(data.errmsg)
          console.log('请求失败', data.debug || data.errmsg)
          throw new Error(data.errmsg)
        }

        return data
      } catch(e) {
        this.$message.error('请求失败')
        throw e
      }
    }
  }
}
