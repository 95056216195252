function resetFilter(){
  return {
    id: '',
    name: '',
    album_id: '',
    album_name: '',
    is_ai: '',
    source_id: '',
    source_from: '',
    audit_status: '',
    audit_uid: '',
    audit_confirm_uid: '',
  }
}

function resetPage() {
  return {
    size: 20,
    page: 0
  }
}

function resetOrder() {
  return {
    orderBy: 'id',
    orderType: 'asc'
  }
}

function updateFilter(state, data){
  for(let key in data){
    state.filters[key] = data[key]
  }
}

function updatePage(state, data){
  for(let key in data){
    state.page[key] = data[key]
  }
}

function updateOrder(state, data){
  for(let key in data){
    state.order[key] = data[key]
  }
}

export default {
  namespaced: true,
  state: {
    page: resetPage(),
    filters: resetFilter(),
    order: resetOrder(),

    lastAuditUid: null,
    lastConfirmUid: null
  },

  mutations:{
    lastConfirmUid(state, lastConfirmUid) {
      state.lastConfirmUid = lastConfirmUid
    },

    lastAuditUid(state, lastAuditUid) {
      state.lastAuditUid = lastAuditUid
    },

    filter(state, data) {
      updateFilter(state, data)
    },

    page(state, data) {
      updatePage(state, data)
    },

    order(state, data) {
      updateOrder(state, data)
    },

    clear(state) {
      state.page = resetPage()
      state.filters = resetFilter()
      state.order = resetOrder()
    }
  }
}
