import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import '@/assets/element-icons/icon.css'
import '@/assets/iconfont/iconfont.css'
import 'element-plus/dist/index.css'
import '@/styles/base.css'
import '@/styles/element.css'
import '@/styles/backend.css'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import JsonEditor from 'json-editor-vue3'
import App from './App.vue'
import router from './router'
import store from './store'
import http from '@/commons/http'
import mixin from '@/commons/mixin'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(http)
  .use(VueViewer)
  .use(JsonEditor)
  .mixin(mixin)
app.use(ElementPlus)
app.mount('#app')


function init() {
  window.addEventListener('resize', () => {
    if (window.resizeTimer) clearTimeout(window.resizeTimer)
    window.resizeTimer = setTimeout(() => {
      store.commit('runtime/resize')
      window.resizeTimer = null
    }, 500)
  })
}

init()
