import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import flags from './modules/flags'
import user from './modules/user'
import tableTrack from './modules/table/track'
import albumTrack from './modules/table/album'
import runtime from './modules/runtime'

export default createStore({
  plugins: [
    createPersistedState({
      storage: {
        setItem(key, val){
          localStorage.setItem(key, val);
        },

        getItem(key){
          return localStorage.getItem(key);
        },

        removeItem(key){
          localStorage.removeItem(key);
        }
      },

      paths: ['flags', 'tableTrack', 'albumTrack']
    })
  ],

  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    flags, user, runtime, tableTrack, albumTrack
  }
})
