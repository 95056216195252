import axios from 'axios'

const xhttp = {
  getURL(url, params){
    if(params){
      let kv = [];
      for(let key in params){
        kv.push(`${key}=${encodeURIComponent(params[key])}`);
      }
      url = `${url}?${kv.join('&')}`;
    }

    return url;
  },

  install(app){
    app.config.globalProperties.$http = axios;

    // 请求拦截器
    axios.interceptors.request.use(function (config) {

      if(!config.params)config.params = {};
      if(!config.headers)config.headers = {};

      // if(!config.headers['Content-Type'])config.headers['Content-Type'] = 'application/json';

      // 禁止缓存
      config.headers['Cache-Control'] = 'no-cache';
      config.headers['Pragma'] = 'no-cache';
      config.headers['Expires'] = -1;

      // 表示XMLHttpRequest
      config.headers['X-Requested-With'] = 'XMLHttpRequest';

      // json转码
      if((config.method === 'post' || config.method === 'put') && config.headers['Content-Type'] === 'application/json'){
        config.transformRequest = function(data){
          return data ? JSON.stringify(data) : null
        };
      }

      // 标记响应类型是json
      if(!config.responseType){
        config.responseType = 'json';
      }

      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    // 兼容IE
    axios.interceptors.response.use(response => {
      // IE 8-9
      if (response.data == null && response.config.responseType === 'json' && response.request.responseText != null) {
        try {
          // eslint-disable-next-line no-param-reassign
          response.data = JSON.parse(response.request.responseText);
        } catch (e) {
          // ignored
        }
      }
      return response;
    });

    // 响应拦截器
    axios.interceptors.response.use(function (response) {
      // 兼容IE bug
      if(response.data && (typeof response.data === 'string') && response.data.indexOf('success') !== -1){
        try{
          response.data = JSON.parse(response.data);
        }catch(e){}
      }

      if(response.data && response.data.msg && response.data.msg !== 'success'){
        let e = new Error(response.data.msg);
        e.response = response;
        throw e;
      }

      return response.data;
    }, function (error) {
      return Promise.reject(error);
    });
  }
};

export default xhttp;
