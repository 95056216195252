function reset(){
  return {
    mexpand: true
  }
}

function update(state, data){
  for(let key in state){
    state[key] = data[key] || ''
  }
}

export default {
  namespaced: true,
  state: reset(),

  mutations:{
    update(state, data){
      update(state, data)
    }
  }
}
