<template>
  <div class="container flex layout" :class="{ mexpand }">
    <div class="layout-left flex-column">
      <el-menu router :default-openeds="[$route.meta.lv1]" :default-active="$route.meta.lv2" :collapse="collapsed" class="flex-1 layout-left-menu">
        <el-sub-menu :index="group.path" v-for="(group, index) in menus" :key="index">
          <template #title>
            <i :class="group.icon" />
            <span style="padding-left:6px">{{group.title}}</span>
          </template>

          <el-menu-item :index="menu.path" v-for="(menu, inx) in group.children" :key="inx">
            <i :class="menu.icon"></i>
            <template v-slot:title>
              <span>{{menu.title}}</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
    <div class="layout-center flex-1">
      <div class="layout-center-wrapper">
        <div class="container layout-center-inner">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      mexpand: state => state.flags.mexpand,
    })
  },

  data() {
    return {
      collapsed: false,
      menus: [
        {
          title: '调试器', path: '/p/test', children: [
            { title: 'AI播放器', path: '/p/test/player/index' }
          ]
        }
      ]
    }
  },

  created() {
    console.log(this.$route.path)
  },

  methods: {
    toggle() {
      this.$store.commit('flag/update', { mexpand: !this.mexpand })
    },

    toggleMenu(menu) {
      if (this.$route.path === menu.path) return
      this.$router.push({
        path: menu.path,
        params: { router: true }
      })
    }
  }
}
</script>

<style>
.layout-left, .layout-center{
  height:100%;
  flex-shrink:0;
}

.layout-left{
  width:0;
  overflow:hidden;
  background: #fff;
}

.mexpand .layout-left{
  width:200px;
}

.layout-center{
  position:relative;
}

.layout-center-wrapper{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
}

.layout-center-inner{
  position:relative;
  overflow:auto;
}

.layout-menu{
  padding:16px 39px 16px 37px;
  cursor:pointer;
  position:relative;
}

.layout-menu.actived {
  color:#196AD4;
  background: #ddecff;
}

.layout-menu:hover{
  background:rgba(0, 0, 0, 0.1);
}

.layout-container{
  height:100%;
  width:100%;
  display:flex;
  flex-flow:column;
}

.layout-container > * {
  width:100%;
}

.layout-bar{
  border-bottom:1px solid #ebebeb;
}

.layout-bar-tabs.layout-bar{
  align-items:flex-end;
  border-bottom:none;
  padding-left:0;
}

.layout-bar-tabs .el-tabs{
  width:100%;
}

.layout-bar-tabs .el-tabs__nav{
  margin-left:12px;
}

.layout-bbar{
  border-top:1px solid #ebebeb;
}

.layout-bbar, .layout-bar{
  box-sizing:border-box;
  padding-left:12px;
  display:flex;
  align-items:center;
  height:48px;
}

.layout-content {
  flex:1;
  overflow:auto;
}

.layout-content-padding {
  padding:6px;
}

.layout-left-menu{
  width:100%;
}

.layout-bar-margin-left{
  margin-left:6px;
}

.layout-bar-margin-right{
  margin-right:6px;
}

.layout-bar-split{
  height:20px;
  width:1px;
  background:#ccc;
  margin:0 6px;
}
</style>
