import { createRouter, createWebHistory } from 'vue-router'
import layout from '@/components/layout/index'

const routes = [
  {
    path: '/',
    redirect: '/p/test/player/index'
  },
  {
    path: '/p',
    redirect: '/p/test/player/index',
    component: layout,
    children: [
      // 导入管理
      { path: 'resource/import/index', meta: { lv1: '/p/resource', lv2: '/p/resource/import/index' }, component: () => import(/* webpackChunkName: "resource-import" */ '@/views/resource/import/index.vue') },
      // 主播管理
      { path: 'resource/host/index', meta: { lv1: '/p/resource', lv2: '/p/resource/host/index' }, component: () => import(/* webpackChunkName: "resource-host" */ '@/views/resource/host/index.vue') },
      // 专辑管理
      { path: 'resource/album/index', meta: { lv1: '/p/resource', lv2: '/p/resource/album/index' }, component: () => import(/* webpackChunkName: "resource-album" */ '@/views/resource/album/index.vue') },
      // 单曲管理
      { path: 'resource/track/index', meta: { lv1: '/p/resource', lv2: '/p/resource/track/index' }, component: () => import(/* webpackChunkName: "resource-index" */ '@/views/resource/track/index.vue') },
      // AI单曲详情
      {
        path: 'resource/track/ai/index/:trackId', meta: { lv1: '/p/resource', lv2: '/p/resource/track/index' }, component: () => import(/* webpackChunkName: "resource-ai" */ '@/views/resource/ai/index.vue'),
        children: [
          { path: 'info', meta: { lv1: '/p/resource', lv2: '/p/resource/track/index', tab: 'info' }, component: () => import(/* webpackChunkName: "resource-ai" */ '@/views/resource/ai/info.vue') },
          { path: 'closed', meta: { lv1: '/p/resource', lv2: '/p/resource/track/index', tab: 'closed' }, component: () => import(/* webpackChunkName: "resource-ai" */ '@/views/resource/ai/closed.vue') },
          { path: 'open', meta: { lv1: '/p/resource', lv2: '/p/resource/track/index', tab: 'open' }, component: () => import(/* webpackChunkName: "resource-ai" */ '@/views/resource/ai/open.vue') },
        ]
      },

      // AI题目管理
      { path: 'test/player/index', component: () => import(/* webpackChunkName: "testPlayer" */ '@/views/test/player/index.vue') },

      // 老师管理
      { path: 'spoken/teacher/index', component: () => import(/* webpackChunkName: "spoken" */ '@/views/spoken/teacher/index.vue') },

      // 主题管理
      { path: 'spoken/topic/index', component: () => import(/* webpackChunkName: "spoken" */ '@/views/spoken/topic/index.vue') },

      // lingos - index
      { path: 'test/lingos/index', component: () => import(/* webpackChunkName: "lingos" */ '@/views/test/lingos/index.vue') },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
