function reset(){
  return {
    resized: 0
  }
}

function update(state, data){
  for(let key in state){
    state[key] = data[key] || ''
  }
}

export default {
  namespaced: true,
  state: reset(),

  mutations:{
    resize(state) {
      state.resized = state.resized + 1
    },

    update(state, data){
      update(state, data)
    }
  }
}
