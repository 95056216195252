function reset(){
  let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZGV2aWNlLWFwaS5kZXYubGluZy5zcGFjZS9yb2JvdC9sb2dpbiIsImlhdCI6MTcxNzAzMzU1OSwiZXhwIjo1MzE3MDMzNTU5LCJuYmYiOjE3MTcwMzM1NTksImp0aSI6ImVnY0RhRFlmeUIzVHBWVlgiLCJzdWIiOiIxMDAwMiIsInBydiI6IjFlNDAwNGVhM2UyNDgyMjY2ZGU5MjhjODliZjBmOGU5N2FjMjU5M2MiLCJ0eXBlIjoicm9ib3RzIiwic2VxIjoiazRLV0o3dloiLCJ1c2VyX2lkIjpmYWxzZSwiY2hpbGRfaWQiOjEwMDQxLCJkZXZpY2VfaWQiOjEwMDAyfQ.1wo4qm8GUAa9IG9i3fJle12YM3cWgGZP9PzLF0z1PUU'
  if (window.location.host === 'test-admin-robot.ling.space') {
    token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZGV2aWNlLWFwaS50ZXN0Lmxpbmcuc3BhY2Uvcm9ib3QvbG9naW4iLCJpYXQiOjE3MTg2MTQ0NzMsImV4cCI6NTMxODYxNDQ3MywibmJmIjoxNzE4NjE0NDczLCJqdGkiOiJWNk9aTVdSQ0VSZXlIeGtxIiwic3ViIjoiMTAwMDIiLCJwcnYiOiIxZTQwMDRlYTNlMjQ4MjI2NmRlOTI4Yzg5YmYwZjhlOTdhYzI1OTNjIiwidHlwZSI6InJvYm90cyIsInNlcSI6Ik01blZwN3lXIiwidXNlcl9pZCI6MTAwMDEsImNoaWxkX2lkIjoxMDAwMywiZGV2aWNlX2lkIjoxMDAwMn0.2y7QFMNK_7fU8nZULIICsl7Zje6dszuziJBZbuFnLQ0'
  }
  return {
    token
  }
}

function update(state, data){
  for(let key in state){
    state[key] = data[key] || ''
  }
}

export default {
  namespaced: true,
  state: reset(),

  mutations:{
    update(state, data){
      update(state, data)
    }
  }
}
